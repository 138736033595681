


import React, { useState } from "react";
import "../../styles/Service/servicecontact.css";
import Fade from "react-reveal/Fade";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaTiktok } from 'react-icons/fa';

const Servicecontact = () => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValid(validateEmail(inputValue));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      console.log('Email is valid:', email);
    } else {
      console.log('Invalid email');
    }
  };

  return (
    <div className="container p-5 mx-auto">
      <Fade bottom duration={1500}>
        <section className="mb-8 text-center">
          <h2 className="mb-2 text-2xl font-bold">
            Contact<span className="text-indigo-600"> Us</span>
          </h2>
          <h1 className="mb-4 text-xl">Have a project in mind! Drop me a line.</h1>
          <p className="text-gray-600">
            Got a project? Drop me a line if you want to work together on something exciting. Or do you need our help? Feel free to contact us.
          </p>
        </section>

        <div className="grid w-auto grid-cols-1 gap-4 sm:grid-cols-12">
          <div className="w-auto p-4 bg-white rounded-md shadow-md sm:col-span-4">
            <h2 className="mb-3 text-lg font-semibold">Get in touch</h2>
            <ul>
              <li className="flex items-center mb-2">
                <img className="w-6 h-6 mr-2" src={require("../../Assets/iconemail.png")} alt="Email" />
                <div>
                  <p>Email us</p>
                  <p>info@tewostech.com</p>
                </div>
              </li>
              <li className="flex items-center mb-2">
                <img className="w-6 h-6 mr-2" src={require("../../Assets/iconphone.png")} alt="Phone" />
                <div>
                  <p className="font-medium">Phone number</p>
                  <p>0911516413</p>
                </div>
              </li>
              <li className="flex items-center mb-2">
                <img className="w-6 h-6 mr-2" src={require("../../Assets/iconmarker.png")} alt="Location" />
                <div>
                  <p>Gerji Mehrat hail,</p>
                  <p>Addis Ababa, Ethiopia</p>
                </div>
              </li>
            </ul>
          </div>

          <div className="rounded-md shadow-md lg:col-span-8">

            <form className="contact-form">
              <div className="subdiv11">
                <label className="formcolor1">FullName </label><br></br>
                <input type="text" id="fname" name="firstname" placeholder="Your Name.." className="formbox1" required></input> <br></br>
                <label className="formcolor1">email</label> <br></br>
                <input type="email" id="email" name="email" placeholder="example@gmail.com.." className="formbox1" required></input>

              </div>

              <div className="subdiv2">
                <label className="formcolor1">Phone</label> <br></br>
                <input type="text" id="fname" name="firstname" placeholder="Your Phone no." className="formbox1" required></input><br></br>
                <label className="formcolor1">Subject</label> <br></br>
                <input type="text" id="fname" name="firstname" placeholder="Your Subject." className="formbox1" required></input><br></br>

              </div>
              <div className="subdiv11">
                <label className="formcolortext " >Leave us a message</label><br></br>
                <textarea id="subject" name="subject" placeholder="Write something.." className="textarea" required></textarea><br></br>
                <input type="submit" value="Send Message" className="mt-2 submit1"></input>

              </div>

            </form>
          </div>
        </div>

        <div className="mt-8 text-center">
          <h3 className="mb-2 text-lg font-semibold">Follow us</h3>
          <div className="flex items-center justify-center space-x-4">
            <a href="https://www.facebook.com/profile.php?id=100092359218655"><img src={require("../../Assets/Social Media (1).png")} alt="Social 1" /></a>
            <a href="https://www.instagram.com/tewos_technology/"><img src={require("../../Assets/Social Media.png")} alt="Facebook" /></a>
            <a href="https://www.linkedin.com/company/tewos-technology/"><img src={require("../../Assets/linkden.png")} alt="LinkedIn" /></a>
            <a href="https://www.tiktok.com/@tewos_technology?is_from_webapp=1&sender_device=pc
https://www.tiktok.com/@tewos_technology?is_from_webapp=1&sender_device=pc"><img src={require("../../Assets/tiktok.png")} width={50} height={40} className="bg-[#0E828F] rounded-sm " alt="LinkedIn" /></a>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Servicecontact;
