import React, { useState, useRef } from "react";
import { boloRegisteration, getAllUsers } from "../../api/bolo/boloSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";

function AddNews() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState();
  const [formData, setFormData] = useState({
    id: "",
    headline: "",
    source: "",
    content: "",
    url: "",
    publication_date: "",
    category_id: "",
    user_id: "",
    created_at: "",
    updated_at: "",
    medias: "",
  });

  const editor = useRef(null);
  const [content, setContent] = useState(""); // Store editor content separately

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditorChange = (newContent) => {
    setFormData((prevState) => ({
      ...prevState,
      content: newContent, // Update content in formData
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDatas = new FormData();
      formDatas.append("headline", formData.headline);
      formDatas.append("content", formData.content); // Add editor content here
      formDatas.append("source", formData.source);
      formDatas.append("url", formData.url);
      formDatas.append("publication_date", formData.publication_date);
      formDatas.append("category_id", formData.category_id);
      formDatas.append("user_id", formData.user_id);
      formDatas.append("created_at", formData.user_id);
      formDatas.append("updated_at", formData.updated_at);

      if (images) {
        formDatas.append("media", images);
      }

      const form = e.currentTarget;
      if (form.checkValidity()) {
        try {
          const data = localStorage.getItem("passUser");
          const token = JSON.parse(data).accessToken;
          const res = await axios.post(
            "https://api.tewostech.com/api/news",
            formDatas,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.data) {
            toast.success("Registered Successfully!", {
              position: "top-right",
            });
            navigate("/admin/news");
            dispatch(getAllUsers());
          }
        } catch (error) {
          return error.code;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="m-4">
      <div className="w-full mx-auto bg-white rounded-lg">
        <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Add News</h2>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-gray-700" htmlFor="formBasicName">
                  Title
                </label>
                <input
                  id="formBasicName"
                  type="text"
                  placeholder="Enter your title"
                  name="headline"
                  value={formData.headline}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <JoditEditor
                  ref={editor}
                  value={formData.content} // Bind the value of JoditEditor
                  onChange={handleEditorChange} // Use the custom handler
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Source
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="Enter source"
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  URL
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="URL"
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicRoletype"
                >
                  Category
                </label>
                <select
                  id="formBasicRoletype"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Category</option>
                  <option value="1">Transport</option>
                  <option value="2">Yene Mekina</option>
                  <option value="3">Bolo</option>
                </select>
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Publication Date
                </label>
                <input
                  id="formBasicPassword"
                  type="date"
                  placeholder="Insert publication date"
                  name="publication_date"
                  value={formData.publication_date}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                className="block text-gray-700"
                htmlFor="formBasicPassword"
              >
                Content Images
              </label>
              <input
                id="formBasicPassword"
                type="file"
                placeholder="Insert Bolo Advert"
                onChange={(e) => setImages(e.target.files[0])}
                required
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="grid justify-end grid-cols-2 gap-4 p-4 mt-4 border-t border-gray-200">
              <button
                type="submit"
                className="py-2 m-4 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Register
              </button>
              <button
                type="button"
                className="py-2 m-4 text-white bg-red-600 rounded-md hover:bg-red-700"
                onClick={() => navigate("/admin/news")}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNews;
